import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import {
  Changelog,
  ChangelogPublicAPI,
} from "containers/Projects/components/Changelog/Changelog";
import { Comments } from "containers/Projects/components/Comments/Comments";
import { CompEventWidget } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget";
import {
  ExplorerContext,
  ExplorerState,
} from "containers/Projects/components/Explorer/Explorer.context";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  AuthorizationWorkflowAudit,
  ChangeInstructionItemStatusMutation,
  ChangeInstructionItemStatusMutationVariables,
  CompEventRegardingType,
  Contract,
  EditInstructionItemMutation,
  EditInstructionItemMutationVariables,
  InstructionItem,
  InstructionItemExtendedQuery,
  InstructionItemExtendedQueryVariables,
  ItemStatusOption,
  ProductSchema,
  ProductType,
  User,
} from "generated/graphql";
import { changeInstructionItemStatusMutation } from "graphql/mutations/changeInstructionItemStatus";
import { editInstructionItemMutation } from "graphql/mutations/editInstructionItem";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { ErrorHandlingType, useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useHasAccess } from "hooks/useHasAccess";
import { useShallDisplayCompEventWidget } from "hooks/useShallDisplayCompEventWidget";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { EWInstructionDetailsHeader } from "../../components/EWInstructionDetailsHeader";
import { instructionItemExtendedQuery } from "./InstructionDetails.query";
import { NewAppPaths } from "helpers/paths/paths";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useNavigateToRegisterPage } from "containers/Projects/hooks/useNavigateToRegisterPage";
import { useShallDisplayVariationWidget } from "containers/Projects/hooks/useShallDisplayVariationWidget";
import { VariationWidget } from "containers/Projects/components/Variations/VariationWidget/VariationWidget";
import { isNECContractType } from "containers/Projects/Projects.utils";
import { NotifiedUsersWidgetHeader } from "containers/Projects/components/NotifiedUsers/NotifiedUsersWidgetHeader";
import { NotifiedUsers } from "containers/Projects/components/NotifiedUsers/NotifiedUsers";

export const InstructionDetails = () => {
  const { productInstanceId, instructionItemId } = useParams();
  const handleNavigateToRegisterPage = useNavigateToRegisterPage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const [changelogApiRef, setChangelogApiRef] = useState<ChangelogPublicAPI>();

  const reloadChangelog = useCallback(() => {
    changelogApiRef?.reload();
  }, [changelogApiRef]);

  const {
    data: instructionData,
    refetch: refetchInstructionData,
    loading: instructionDataLoading,
    error,
  } = useGraphQuery<
    InstructionItemExtendedQuery,
    InstructionItemExtendedQueryVariables
  >(
    instructionItemExtendedQuery,
    {
      variables: { id: instructionItemId! },
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  const isContractTypeNEC = useMemo(
    () =>
      isNECContractType(
        instructionData?.instructionItem?.productInstance.contract as Contract
      ),
    [instructionData]
  );

  const contractTypeId = useMemo(
    () =>
      instructionData?.instructionItem?.productInstance.contract.contractTypeId,
    [instructionData]
  );

  const canAccessCompEventWidget = useShallDisplayCompEventWidget(
    CompEventRegardingType.Instruction,
    instructionItemId!,
    instructionData?.instructionItem?.compEvent?.id,
    instructionData?.instructionItem?.productInstance.contract.id
  );

  const canAccessVariationWidget = useShallDisplayVariationWidget(
    instructionData?.instructionItem?.variation?.id ?? undefined,
    instructionData?.instructionItem?.productInstance.contract.id
  );

  const [changeInstructionStatus, { loading: changeInstructionStatusLoading }] =
    useGraphMutation<
      ChangeInstructionItemStatusMutation,
      ChangeInstructionItemStatusMutationVariables
    >(
      changeInstructionItemStatusMutation,
      {
        update: (_cache) => {
          refetchInstructionData();

          // cache.evict({ id: "ROOT_QUERY", fieldName: "instructionItems" });
          // cache.gc();
          reloadChangelog();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Instructions.instruction"),
      })
    );

  const [doEditInstruction] = useGraphMutation<
    EditInstructionItemMutation,
    EditInstructionItemMutationVariables
  >(
    editInstructionItemMutation,
    {
      update: () => {
        refetchInstructionData();
        reloadChangelog();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Instructions.instruction"),
    })
  );

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    await doEditInstruction({
      variables: {
        input: {
          id: instructionItemId!,
          attachments:
            attachmentsUpdated ?? instructionData!.instructionItem?.attachments, // see if needs ?? []; The same for EW
        },
      },
    });
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    (
      (instructionData?.instructionItem?.attachments as Attachment[]) ?? []
    ).filter((attach) => attach.status === AttachmentStatus.Active) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const handleChangeInstructionStatus = async (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => {
    await changeInstructionStatus({
      variables: {
        input: {
          id: instructionItemId!,
          newStatusId: newStatus.id,
          reasonId,
          remarks,
        },
      },
    });
  };

  useEffect(() => {
    if (changelogApiRef) {
      reloadChangelog();
    }
  }, [changelogApiRef, reloadChangelog]);

  useEffect(() => {
    if (instructionData) {
      changeExplorerEntities({
        projectId:
          instructionData.instructionItem?.productInstance.contract.project.id,
        contractId:
          instructionData.instructionItem?.productInstance.contract.id,
        productId: instructionData.instructionItem?.productInstance.product.id,
        productInstanceId: instructionData.instructionItem?.productInstanceId,
      });
    }
  }, [changeExplorerEntities, instructionData]);

  useEffect(() => {
    if (location.state) {
      changeExplorerEntities(location.state as ExplorerState);
    } else {
      // state not preset, needs fetching
      setExplorerDataLoading(true);
    }
  }, [setExplorerDataLoading, location, changeExplorerEntities]);

  useEffect(() => {
    if (instructionData) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, instructionData]);

  useEffect(() => {
    if (error && !instructionData) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [error, instructionData, navigate, clearExplorerData]);

  const isCurrentUserOwner =
    instructionData?.instructionItem?.ownerId === authenticatedUser?.id;
  const canChangeStatus =
    useHasAccess(
      undefined,
      [PermissionEnum.ChangeStatus],
      productInstanceId ?? undefined
    ) || isCurrentUserOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserOwner;

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={
          instructionData?.instructionItem?.productInstance.contract.timeZone
        }
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <EWInstructionDetailsHeader
            title={instructionData?.instructionItem?.title ?? ""}
            titleSuffix={`(${instructionData?.instructionItem?.number})`}
            statusOptionId={
              instructionData?.instructionItem?.statusOptionId || ""
            }
            dataLoading={
              instructionDataLoading || changeInstructionStatusLoading
            }
            productType={ProductType.Instructions}
            statusOptions={
              (instructionData?.instructionItem?.productInstance
                .statusCollection.statusOptions.items as ItemStatusOption[]) ??
              []
            }
            isRecorded={!!instructionData?.instructionItem?.offline}
            onStatusChange={
              canChangeStatus ? handleChangeInstructionStatus : undefined
            }
            onBack={handleNavigateToRegisterPage}
          />
          <Box mt={3}>
            {instructionDataLoading ||
            !instructionData ||
            !instructionData.instructionItem ||
            !contractTypeId ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode={false}
                productItemType={ProductType.Instructions} // TODO
                schema={
                  instructionData.instructionItem.productInstance
                    .productSchema as ProductSchema
                }
                authAudit={
                  (instructionData.instructionItem
                    .authorizationWorkflowAudit as AuthorizationWorkflowAudit) ??
                  undefined
                }
                isNECContractType={isContractTypeNEC}
                contractTypeId={contractTypeId}
                metadata={{
                  owner: instructionData.instructionItem.owner as User,
                  creator: instructionData.instructionItem.creator as User,
                  dateModified: instructionData.instructionItem.dateModified,
                  dateCreated: instructionData.instructionItem.dateCreated,
                  productItemId: instructionData.instructionItem.id,
                  dateSent: instructionData.instructionItem.dateSent,
                  offline: instructionData.instructionItem.offline,
                }}
                schemaValues={instructionData.instructionItem.data}
                contractCurrency={
                  instructionData.instructionItem.productInstance.contract
                    .valueCurrency ?? ""
                }
                contractTimezone={
                  instructionData.instructionItem.productInstance.contract
                    .timeZone ?? ""
                }
                productInstanceId={
                  instructionData.instructionItem.productInstanceId
                }
                onOwnerChange={reloadChangelog}
                mainColumnExtraWidgets={
                  <>
                    <Grid item xs={12} key="attachments" position="relative">
                      <SectionContainer>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          timezone={
                            instructionData?.instructionItem?.productInstance
                              .contract.timeZone
                          }
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid item xs={12} key="comments" position="relative">
                      <SectionContainer>
                        <Comments
                          productType={ProductType.Instructions}
                          productItemId={instructionItemId!}
                          productInstanceId={productInstanceId!}
                          onCommentAdded={reloadChangelog}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
                secondaryColumnExtraWidgets={
                  <>
                    {canAccessCompEventWidget && (
                      <Box width="100%" key="comp-event" position="relative">
                        <SectionContainer>
                          <CompEventWidget
                            loading={instructionDataLoading}
                            regardingId={instructionItemId!}
                            compEventId={
                              instructionData?.instructionItem.compEvent?.id
                            }
                            contract={
                              instructionData.instructionItem.productInstance
                                .contract as Contract
                            }
                            projectId={
                              instructionData.instructionItem.productInstance
                                .contract.project.id
                            }
                            projectName={
                              instructionData.instructionItem.productInstance
                                .contract.project.friendlyName
                            }
                            instructionItem={
                              instructionData.instructionItem as InstructionItem
                            }
                            onChange={refetchInstructionData}
                          />
                        </SectionContainer>
                      </Box>
                    )}
                    {canAccessVariationWidget && (
                      <Box width="100%" key="variation" position="relative">
                        <SectionContainer>
                          <VariationWidget
                            loading={instructionDataLoading}
                            regardingId={instructionItemId!}
                            variationId={
                              instructionData.instructionItem.variation?.id
                            }
                            contract={
                              instructionData.instructionItem.productInstance
                                .contract as Contract
                            }
                            projectId={
                              instructionData.instructionItem.productInstance
                                .contract.project.id
                            }
                            projectName={
                              instructionData.instructionItem.productInstance
                                .contract.project.friendlyName
                            }
                            instructionItem={
                              instructionData.instructionItem as InstructionItem
                            }
                            onChange={refetchInstructionData}
                          />
                        </SectionContainer>
                      </Box>
                    )}
                    <Box width="100%" key="changelog" position="relative">
                      <CollapsibleSectionContainer
                        collapsible
                        title="Changelog"
                        maxHeight="600px"
                        overflow="auto"
                      >
                        <Changelog
                          productItemId={instructionData.instructionItem.id}
                          productType={ProductType.Instructions}
                          productInstanceId={productInstanceId!}
                          contractTimezone={
                            instructionData?.instructionItem.productInstance
                              .contract.timeZone ?? ""
                          }
                          ref={(apiRef) => {
                            setChangelogApiRef(apiRef!);
                          }}
                        />
                      </CollapsibleSectionContainer>
                    </Box>
                    {instructionData.instructionItem.notificationRecipients
                      .length > 0 && (
                      <Box
                        width="100%"
                        key="notified-users"
                        position="relative"
                      >
                        <CollapsibleSectionContainer
                          collapsible
                          initialCollapseState={true}
                          title={
                            <NotifiedUsersWidgetHeader
                              recipientsCount={
                                instructionData.instructionItem
                                  .notificationRecipients.length
                              }
                            />
                          }
                        >
                          <NotifiedUsers
                            recipients={
                              instructionData.instructionItem
                                .notificationRecipients
                            }
                          />
                        </CollapsibleSectionContainer>
                      </Box>
                    )}
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
