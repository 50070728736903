import { Box, useTheme, Collapse } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { User, UserStatus } from "generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { getColumns } from "./CompanyUsers.constants";
import { useNavigate, useParams } from "react-router-dom";
import { exportToExcel } from "helpers/exportToExcel";
import { useActiveRemovedSuspendedStatusOptions } from "components/StatusTag/useActiveRemovedSuspendedOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { UsersIcon } from "components/Icons/UsersIcon";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { StatusOption } from "components/StatusTag/StatusTag";
import { getUserName } from "helpers/miscelaneous";
import { NewAppPaths } from "helpers/paths/paths";
import { excelDateTimeISOFormat } from "constants/index";

export type CompanyUsersProps = {
  companyName?: string;
  users: User[];
  onUserStatusChange: (row: User, newStatus: UserStatus) => void;
  onResendRegisterInvitation: (row: User) => void;
  loading?: boolean;
};

export const CompanyUsers: React.FC<CompanyUsersProps> = ({
  companyName,
  users,
  loading,
  onUserStatusChange,
  onResendRegisterInvitation,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { companyId } = useParams();
  const companyStatusOptions =
    useActiveRemovedSuspendedStatusOptions() as StatusOption<UserStatus>[];
  const navigate = useNavigate();

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [showUsers, setShowUsers] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "registered", sort: "desc" },
    { field: "name", sort: "asc" },
  ]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [UserStatus.Active, UserStatus.Suspended],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<User>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.Companies.children.CompanyDetails.children.UserDetails.pathConstructor(
            companyId!,
            rowData.row.id
          )
        );
      }
    },
    [navigate, companyId]
  );

  const handleInviteUser = () => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.Companies.children.CompanyDetails.children.InviteUser.pathConstructor(
        companyId!
      )
    );
  };

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name" },
      { header: t("common.labels.email"), key: "email" },
      {
        header: t("AdminConsole.Users.labels.registered"),
        key: "registered",
      },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("AdminConsole.Users.labels.dateInvited"),
        key: "dateInvited",
        numFmt: excelDateTimeISOFormat,
      },
      {
        key: "invitedBy",
        header: t("common.labels.invitedBy"),
      },
    ];

    const rows = users
      .filter((user) => (selectionModel || []).indexOf(user.id) >= 0)
      .map((user) => ({
        ...user,
        name: getUserName(user),
        dateInvited: user.dateInvited ? new Date(user.dateInvited) : "",
        invitedBy: getUserName(user.invitedBy),
      }));

    exportToExcel(
      `${companyName}-${t("AdminConsole.Users.labels.users")}`,
      columns,
      rows
    );
  };

  const columns = useMemo(
    () =>
      getColumns(
        theme,
        companyStatusOptions,
        onUserStatusChange,
        onResendRegisterInvitation,
        t
      ),
    [
      onUserStatusChange,
      onResendRegisterInvitation,
      companyStatusOptions,
      theme,
      t,
    ]
  );

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Users.labels.users")}
        actionButtonCaption={t("AdminConsole.Users.labels.inviteUser")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        onActionButtonClick={handleInviteUser}
        icon={<UsersIcon />}
        onToggleCollapse={() => setShowUsers((state) => !state)}
        collapsed={!showUsers}
      />
      <Collapse in={showUsers}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={users}
            columns={columns}
            getRowId={(rowData: User) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            onRowClick={handleRowClick}
            getCellClassName={(params: GridCellParams<any, User, any>) => {
              return params.row.status === UserStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
          />
        </Box>
      </Collapse>
    </Box>
  );
};
