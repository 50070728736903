import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  computeRegardingColumnText,
  useColumns,
} from "./ProjectAuthorizationTrails.constants";
import { exportToExcel } from "../../../../../../helpers/exportToExcel";
import { NewAppPaths } from "helpers/paths/paths";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { Path } from "phosphor-react";
import { StyledDataGrid } from "components/StyledDataGrid";
import { getUserName } from "helpers/miscelaneous";
import { excelDateTimeISOFormat } from "constants/constants";

export type ProjectAuthorizationTrailsProps = {
  auditTrails: AuthorizationWorkflowAudit[];
  projectName?: string;
  loading?: boolean;
  onLoadMore: () => void;
};

const minimumRecordsNoForScroll = 11;

export const ProjectAuthorizationTrails: React.FC<
  ProjectAuthorizationTrailsProps
> = ({ auditTrails, projectName, loading, onLoadMore }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [showAuditTrails, setShowAuditTrails] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "dateInitiated", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("common.labels.contract"),
        key: "contract",
      },
      { header: t("common.labels.product"), key: "product" },
      {
        header: t("AdminConsole.AuthorizationWorkflows.action"),
        key: "action",
      },
      {
        header: t("common.labels.regarding"),
        key: "regarding",
      },
      {
        header: t("AdminConsole.AuthorizationWorkflows.workflow"),
        key: "workflow",
      },
      {
        header: t("common.labels.status"),
        key: "status",
      },
      {
        header: t("AdminConsole.AuthorizationAuditTrails.initiatedBy"),
        key: "initiatedBy",
      },
      {
        header: t("AdminConsole.AuthorizationAuditTrails.dateInitiated"),
        key: "dateInitiated",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
    ];

    const rows = auditTrails
      .filter(
        (auditTrail) => (selectionModel || []).indexOf(auditTrail.id) >= 0
      )
      .map((auditTrail) => ({
        ...auditTrail,
        contract: auditTrail.action.productInstance.contract.friendlyName,
        product: auditTrail.action.productInstance.product.name,
        action: t(
          `Home.Authorizations.AuthorizeDenyModal.actions.${auditTrail.action.productOutputAction.name}`
        ),
        regarding: computeRegardingColumnText(auditTrail),
        workflow: auditTrail.workflow.name,
        status: auditTrail.status,
        initiatedBy: getUserName(auditTrail.initiatedBy),
        dateInitiated: auditTrail.dateInitiated
          ? new Date(auditTrail.dateInitiated)
          : "",
        dateCreated: auditTrail.dateCreated
          ? new Date(auditTrail.dateCreated)
          : "",
      }));

    exportToExcel(
      `${projectName}-${t(
        "AdminConsole.AuthorizationAuditTrails.authorizationAuditTrails"
      )}`,
      columns,
      rows
    );
  };

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<AuthorizationWorkflowAudit>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.Projects.children.AuthorizationAuditTrailDetails.pathConstructor(
            projectId!,
            rowData.row.id
          )
        );
      }
    },
    [navigate, projectId]
  );

  const columns = useColumns();

  const gridHasScroll = auditTrails.length >= minimumRecordsNoForScroll;

  return (
    <Box>
      <CollapsibleHeader
        title={t(
          "AdminConsole.AuthorizationAuditTrails.authorizationAuditTrails"
        )}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={<Path color={theme.palette.primary.main} />}
        onToggleCollapse={() => setShowAuditTrails((state) => !state)}
        collapsed={!showAuditTrails}
      />
      <Collapse in={showAuditTrails}>
        <Box
          sx={{
            maxHeight: 600,
            height: gridHasScroll ? 600 : "unset",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={auditTrails}
            columns={columns}
            getRowId={(rowData: AuthorizationWorkflowAudit) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            onRowClick={handleRowClick}
            onRowsScrollEnd={onLoadMore}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight={!gridHasScroll}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
