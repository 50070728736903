import {
  GridEventListener,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import { ClaimItem, CompEventItem, VariationItem } from "generated/graphql";
import { useCallback, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { exportToExcel } from "../../../../../helpers/exportToExcel";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { useColumns } from "./NextStepsTable.constants";
import {
  getItemDeemingDate,
  getItemDueDate,
  getItemNextStepParty,
  getItemNextStepText,
} from "./NextStepsTable.utils";
import { excelDateTimeISOFormat } from "constants/constants";

export type NextStepItem = CompEventItem | ClaimItem | VariationItem;

export type NextStepsTableProps = {
  items: NextStepItem[];
  loading: boolean;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
  onRowClick: (rowData: NextStepItem) => void;
  onLoadMore: () => void;
};

export const NextStepsTable: React.FC<NextStepsTableProps> = ({
  items,
  loading,
  apiRef,
  selectionModel,
  onSelectionModelChange,
  onRowClick,
  onLoadMore,
}) => {
  const { t } = useTranslation();
  const gridApiRef = useGridApiRef();
  const columns = useColumns();

  const handleExportToExcel = useCallback(async () => {
    if (!items || !items.length) {
      console.warn("No records to export");
      return;
    }

    const columns = [
      {
        header: t("common.labels.project"),
        key: "project",
      },
      { header: t("common.labels.contract"), key: "contract" },
      {
        header: t("Home.NextSteps.nextStep"),
        key: "nextStep",
      },
      {
        header: t("common.labels.regarding"),
        key: "regarding",
      },
      {
        header: t("common.labels.party"),
        key: "party",
      },
      {
        header: t("Home.NextSteps.deemingDate"),
        key: "deemingDate",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.dueDate"),
        key: "dueDate",
        numFmt: excelDateTimeISOFormat,
      },
    ];

    const rows = items
      .filter((item) => (selectionModel || []).indexOf(item.id) >= 0)
      .map((item) => {
        const party = getItemNextStepParty(item);
        const deemingDate = getItemDeemingDate(item);
        const nextStepText = getItemNextStepText(item);
        const dueDate = getItemDueDate(item);

        return {
          ...item,
          project: item.productInstance.contract.project.friendlyName,
          contract: item.productInstance.contract.friendlyName,
          nextStep: t(nextStepText!),
          regarding: item.number,
          party,
          deemingDate,
          dueDate: new Date(dueDate),
        };
      });

    exportToExcel(t("Home.NextSteps.nextSteps"), columns, rows);
  }, [items, selectionModel, t]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<NextStepItem>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        onRowClick(rowData.row);
      }
    },
    [onRowClick]
  );

  const handleClearSelection = useCallback(() => {
    onSelectionModelChange([]);
  }, [onSelectionModelChange]);

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    onSelectionModelChange(newSelectionModel);
  };

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={items}
      columns={columns}
      getRowId={(rowData: NextStepItem) => rowData.id}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading}
      onRowClick={handleRowClick}
      onRowsScrollEnd={onLoadMore}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
