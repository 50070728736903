import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useContractTypeStatusOptions } from "components/StatusTag/useContractTypeStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  dateTimeISOFormat,
  excelDateTimeISOFormat,
  temporaryRowId,
} from "constants/constants";
import {
  AddProductSchemaInput,
  EditProductSchemaInput,
  ProductSchema,
  ProductSchemaStatus,
  User,
} from "generated/graphql";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { useColumns } from "./ProductSchemas.constants";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { TreeStructure } from "phosphor-react";
import { NewAppPaths } from "helpers/paths/paths";
import { getUserName } from "helpers/miscelaneous";

export type ProductSchemasProps = {
  schemas: ProductSchema[];
  productName?: string;
  loading?: boolean;
  onSchemaStatusChange: (
    row: ProductSchema,
    newStatus: ProductSchemaStatus
  ) => void;
  onSchemaAdd: (schemaData: Omit<AddProductSchemaInput, "productId">) => void;
  onSchemaUpdate: (
    schemaData: Omit<EditProductSchemaInput, "productId">
  ) => void;
};

export const ProductSchemas: React.FC<ProductSchemasProps> = ({
  schemas,
  productName,
  loading,
  onSchemaAdd,
  onSchemaStatusChange,
  onSchemaUpdate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { productId } = useParams();
  const { authenticatedUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const statusOptions =
    useContractTypeStatusOptions() as StatusOption<ProductSchemaStatus>[];

  const [rows, setRows] = useState<ProductSchema[]>(schemas);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(schemas)
  );
  const [showSchemas, setShowSchemas] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "registered", sort: "desc" },
    { field: "name", sort: "asc" },
  ]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ProductSchemaStatus.Active, ProductSchemaStatus.Offline],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name" },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("AdminConsole.Products.labels.isDefault"),
        key: "isDefault",
      },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
      { header: t("common.labels.createdBy"), key: "creator" },
    ];

    const rows = schemas
      .filter((schema) => (selectionModel || []).indexOf(schema.id) >= 0)
      .map((schema) => ({
        ...schema,
        isDefault: !!schema.isDefault,
        dateCreated: schema.dateCreated ? new Date(schema.dateCreated) : "",
        creator: getUserName(schema.creator),
      }));

    exportToExcel(
      `${productName}-${t("AdminConsole.Products.labels.schemas")}`,
      columns,
      rows
    );
  };

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (rowData: GridRowParams<ProductSchema>, event) => {
      const nameCells = [
        ...window.document.querySelectorAll(
          '.MuiDataGrid-cell[data-field="name"]'
        ),
      ];
      if (!nameCells.some((cell) => cell.contains(event.target as any))) {
        // click was NOT inside "name" cell
        const selection = window.getSelection()?.toString();
        if (!selection) {
          navigate(
            NewAppPaths.authorized.AdminConsole.children.System.children.Products.children.ProductDetails.children.SchemaDetails.pathConstructor(
              productId!,
              rowData.row.id
            )
          );
        }
      }
    },
    [navigate, productId]
  );

  const handleRowChangesCommited = useCallback(
    (
      newRow: GridRowModel<ProductSchema>,
      oldRow: GridRowModel<ProductSchema>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.name) {
          onSchemaAdd({
            name: newRow.name,
            isDefault: newRow.isDefault,
          });
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "name",
            },
          }));
        }
      } else if (
        newRow.name !== oldRow.name ||
        newRow.isDefault !== oldRow.isDefault
      ) {
        onSchemaUpdate({
          id: newRow.id,
          name: newRow.name,
          isDefault: newRow.isDefault,
        });
      }

      return newRow;
    },
    [onSchemaAdd, onSchemaUpdate]
  );

  const handleSchemaStatusChange = useCallback(
    (row: ProductSchema, newStatus: ProductSchemaStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        onSchemaStatusChange(row, newStatus);
      }
    },
    [onSchemaStatusChange]
  );

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        name: "",
        id: temporaryRowId,
        status: ProductSchemaStatus.Active,
        isInternal: false,
        isDefault: false,
        productId: "",
        schemaSections: { items: [] },
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "name",
        },
      }));
    });
  };

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onSchemaStatusChange(
          schemas.find((schema) => schema.id === rowId)!,
          ProductSchemaStatus.Removed
        );
      }
    },
    [onSchemaStatusChange, schemas]
  );

  const handleIsDefaultChange = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                isDefault: !row.isDefault,
              };
            }

            return row;
          })
        );
      } else {
        const updatedSchema = schemas.find((schema) => schema.id === rowId)!;

        onSchemaUpdate({
          id: String(rowId),
          name: updatedSchema.name,
          isDefault: !updatedSchema.isDefault,
        });
      }
    },
    [onSchemaUpdate, schemas]
  );

  const columns = useColumns(
    statusOptions,
    productId!,
    rowModesModel,
    handleSchemaStatusChange,
    handleRowSaveClick,
    handleDeleteRow,
    handleIsDefaultChange
  );

  useEffect(() => {
    setRows(schemas);
    setRowModesModel(computeGridRowModes(schemas));
  }, [schemas]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Products.labels.schemas")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <TreeStructure
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowSchemas((state) => !state)}
        collapsed={!showSchemas}
      />
      <Collapse in={showSchemas}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: ProductSchema) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            onRowClick={handleRowClick}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, ProductSchema, any>
            ) => {
              return params.row.status === ProductSchemaStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
