import {
  AddTimePeriodOverrideInput,
  Contract,
  TimePeriod,
  TimePeriodOverride,
} from "generated/graphql";
import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import { exportToExcel } from "helpers/exportToExcel";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useColumns } from "./TimePeriodsOverride.constants";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { dateTimeISOFormat, temporaryRowId } from "constants/constants";
import { GlobalContext } from "state-management/globalContext/Global.context";
import moment from "moment";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { ClockClockwise } from "phosphor-react";
import { getUserName } from "helpers/miscelaneous";

export type TimePeriodsOverrideProps = {
  timePeriods: TimePeriod[];
  timePeriodsOverride: TimePeriodOverride[];
  contract?: Contract;
  loading?: boolean;
  projectFriendlyName?: string;
  onTimePeriodOverrideAdd: (
    newTimePeriodOverride: AddTimePeriodOverrideInput
  ) => any;
  onTimePeriodOverrideRemove: (id: string) => any;
};

export const TimePeriodsOverride: React.FC<TimePeriodsOverrideProps> = ({
  timePeriods,
  timePeriodsOverride,
  loading,
  projectFriendlyName,
  contract,
  onTimePeriodOverrideAdd,
  onTimePeriodOverrideRemove,
}) => {
  const { t } = useTranslation();
  const { authenticatedUser } = useContext(GlobalContext);
  const theme = useTheme();

  const overridableTimePeriods = useMemo(() => {
    const overridenTimePeriodIds = timePeriodsOverride.map(
      (timePeriodOverride) =>
        timePeriodOverride.timePeriodId ?? timePeriodOverride.timePeriod.id
    );

    return timePeriods.filter(
      (timePeriod) => overridenTimePeriodIds.indexOf(timePeriod.id) < 0
    );
  }, [timePeriods, timePeriodsOverride]);

  const [rows, setRows] = useState<TimePeriodOverride[]>(timePeriodsOverride);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(timePeriodsOverride)
  );
  const [gridVisibility, setGridVisibility] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "timePeriod", sort: "asc" },
  ]);

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const handleExportContractTimePeriodsOverrideToExcel = () => {
    const columns = [
      {
        header: t("AdminConsole.TimePeriods.timePeriod"),
        key: "timePeriod",
        width: 20,
      },
      {
        header: t("common.labels.default"),
        key: "default",
        width: 20,
      },
      {
        header: t("AdminConsole.TimePeriods.duration"),
        key: "duration",
        width: 20,
      },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];

    const rows = timePeriodsOverride
      .filter(
        (timePeriodOverride) =>
          (selectionModel || []).indexOf(timePeriodOverride.id) >= 0
      )
      .map((timePeriodOverride) => ({
        ...timePeriodOverride,
        timePeriod: `${timePeriodOverride.timePeriod.reference} ${timePeriodOverride.timePeriod.description}`,
        default: timePeriodOverride.timePeriod.duration,
        override: timePeriodOverride.duration,
        dateCreated: timePeriodOverride.dateCreated
          ? new Date(timePeriodOverride.dateCreated)
          : "",
        creator: getUserName(timePeriodOverride.creator),
      }));

    exportToExcel(
      `${projectFriendlyName}-${contract!.friendlyName}-${t(
        "AdminConsole.TimePeriods.timePeriodsOverride"
      )}`,
      columns,
      rows
    );
  };

  const handleRowChangesCommited = useCallback(
    async (
      newRow: GridRowModel<TimePeriodOverride>,
      _: GridRowModel<TimePeriodOverride>
    ) => {
      if (!newRow.duration || !newRow.timePeriodId) {
        setRowModesModel((prevData) => ({
          ...prevData,
          [temporaryRowId]: {
            mode: GridRowModes.Edit,
            fieldToFocus: !newRow.duration ? "duration" : "timePeriod",
          },
        }));
        return newRow;
      }

      if (newRow.id === temporaryRowId) {
        const success = await onTimePeriodOverrideAdd({
          contractId: contract!.id,
          timePeriodId: newRow.timePeriodId,
          duration: Number(newRow.duration),
        });

        if (!success) {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "duration",
            },
          }));
        }
      }

      return newRow;
    },
    [onTimePeriodOverrideAdd, contract]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        onTimePeriodOverrideRemove(rowId as string);
      }
    },
    [onTimePeriodOverrideRemove]
  );

  const handleTimePeriodChange = useCallback(
    (row: TimePeriodOverride, newTimePeriodId: any) => {
      const selectedTimePeriod = overridableTimePeriods.find(
        (tp) => tp.id === newTimePeriodId
      )!;

      if (row.id === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) =>
          curRows.map((crtRow) =>
            crtRow.id === row.id
              ? {
                  ...crtRow,
                  timePeriod: selectedTimePeriod,
                  timePeriodId: selectedTimePeriod.id,
                }
              : crtRow
          )
        );
      }
    },
    [overridableTimePeriods]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser!,
        creatorId: authenticatedUser!.id,
        dateCreated: moment(new Date().toString()).format(dateTimeISOFormat),
        id: temporaryRowId,
        contract: contract!,
        contractId: contract!.id,
        duration: "" as any,
        timePeriod: "" as any,
        timePeriodId: "",
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "duration",
        },
      }));
    });
  };

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const columns = useColumns({
    allTimePeriods: timePeriods,
    overridableTimePeriods,
    rowModesModel,
    onTimePeriodChange: handleTimePeriodChange,
    onDeleteRow: handleDeleteRow,
    onSaveRow: handleRowSaveClick,
  });

  useEffect(() => {
    setRows(timePeriodsOverride);
    setRowModesModel(computeGridRowModes(timePeriodsOverride));
  }, [timePeriodsOverride]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.TimePeriods.timePeriodsOverride")}
        visibleRowsCount={rows.length || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportContractTimePeriodsOverrideToExcel}
        onToggleCollapse={() => setGridVisibility((state) => !state)}
        collapsed={!gridVisibility}
        withShadow={false}
        icon={
          <ClockClockwise
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
      />
      <Collapse in={gridVisibility}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            rows={rows}
            columns={columns}
            getRowId={(rowData: TimePeriodOverride) => rowData.id}
            onRowSelectionModelChange={handleGridRowSelectionModelChange}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommited}
            // experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            hideFooter
            checkboxSelection
            disableRowSelectionOnClick
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
