import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import {
  Changelog,
  ChangelogPublicAPI,
} from "containers/Projects/components/Changelog/Changelog";
import { Comments } from "containers/Projects/components/Comments/Comments";
import {
  ExplorerContext,
  ExplorerState,
} from "containers/Projects/components/Explorer/Explorer.context";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  AuthorizationWorkflowAudit,
  ChangeEarlyWarningItemStatusMutation,
  ChangeEarlyWarningItemStatusMutationVariables,
  EarlyWarningItemQuery,
  EarlyWarningItemQueryVariables,
  EditEarlyWarningItemMutation,
  EditEarlyWarningItemMutationVariables,
  ItemStatusOption,
  ProductSchema,
  ProductType,
  User,
} from "generated/graphql";
import { changeEarlyWarningItemStatusMutation } from "graphql/mutations/changeEarlyWarningStatus";
import { editEarlyWarningItemMutation } from "graphql/mutations/editEarlyWarning";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { ErrorHandlingType, useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useHasAccess } from "hooks/useHasAccess";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { EWInstructionDetailsHeader } from "../../components/EWInstructionDetailsHeader";
import { earlyWarningItemQuery } from "./EarlyWarningDetails.query";
import { NewAppPaths } from "helpers/paths/paths";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useNavigateToRegisterPage } from "containers/Projects/hooks/useNavigateToRegisterPage";
import { NotifiedUsers } from "containers/Projects/components/NotifiedUsers/NotifiedUsers";
import { NotifiedUsersWidgetHeader } from "containers/Projects/components/NotifiedUsers/NotifiedUsersWidgetHeader";

export const EarlyWarningDetails = () => {
  const { productInstanceId, ewItemId } = useParams();
  const navigate = useNavigate();
  const handleNavigateToEWRegister = useNavigateToRegisterPage();
  const { t } = useTranslation();
  const location = useLocation();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const [changelogApiRef, setChangelogApiRef] = useState<ChangelogPublicAPI>();

  const reloadChangelog = useCallback(() => {
    changelogApiRef?.reload();
  }, [changelogApiRef]);

  const {
    data: ewData,
    refetch: refetchEWData,
    loading: eWDataLoading,
    error,
  } = useGraphQuery<EarlyWarningItemQuery, EarlyWarningItemQueryVariables>(
    earlyWarningItemQuery,
    {
      variables: { id: ewItemId! },
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  const [changeEWStatus, { loading: changeEWStatusLoading }] = useGraphMutation<
    ChangeEarlyWarningItemStatusMutation,
    ChangeEarlyWarningItemStatusMutationVariables
  >(
    changeEarlyWarningItemStatusMutation,
    {
      update: (_cache) => {
        refetchEWData();

        // cache.evict({ id: "ROOT_QUERY", fieldName: "earlyWarningItems" });
        // cache.gc();
        reloadChangelog();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.EarlyWarnings.earlyWarning"),
    })
  );

  const [doEditEarlyWarning] = useGraphMutation<
    EditEarlyWarningItemMutation,
    EditEarlyWarningItemMutationVariables
  >(
    editEarlyWarningItemMutation,
    {
      update: () => {
        refetchEWData();
        reloadChangelog();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.EarlyWarnings.earlyWarning"),
    })
  );

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    await doEditEarlyWarning({
      variables: {
        input: {
          id: ewItemId!,
          attachments:
            attachmentsUpdated ?? ewData!.earlyWarningItem?.attachments,
        },
      },
    });
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    ((ewData?.earlyWarningItem?.attachments as Attachment[]) ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const handleChangeEWStataus = async (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => {
    await changeEWStatus({
      variables: {
        input: {
          id: ewData!.earlyWarningItem!.id,
          newStatusId: newStatus.id,
          reasonId,
          remarks,
        },
      },
    });
  };

  useEffect(() => {
    if (changelogApiRef) {
      reloadChangelog();
    }
  }, [changelogApiRef, reloadChangelog]);

  useEffect(() => {
    if (ewData) {
      changeExplorerEntities({
        projectId: ewData.earlyWarningItem?.productInstance.contract.project.id,
        contractId: ewData.earlyWarningItem?.productInstance.contract.id,
        productId: ewData.earlyWarningItem?.productInstance.product.id,
        productInstanceId: ewData.earlyWarningItem?.productInstanceId,
      });
    }
  }, [changeExplorerEntities, ewData]);

  useEffect(() => {
    if (location.state) {
      changeExplorerEntities(location.state as ExplorerState);
    } else {
      // state not preset, needs fetching
      setExplorerDataLoading(true);
    }
  }, [setExplorerDataLoading, location, changeExplorerEntities]);

  useEffect(() => {
    if (ewData) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, ewData]);

  useEffect(() => {
    if (error && !ewData) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [error, ewData, navigate, clearExplorerData]);

  const isCurrentUserEWOwner =
    ewData?.earlyWarningItem?.ownerId === authenticatedUser?.id;
  const canChangeStatus =
    useHasAccess(
      undefined,
      [PermissionEnum.ChangeStatus],
      productInstanceId ?? undefined
    ) || isCurrentUserEWOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserEWOwner;

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        contractTimezone={
          ewData?.earlyWarningItem?.productInstance.contract.timeZone
        }
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <EWInstructionDetailsHeader
            title={ewData?.earlyWarningItem?.title ?? ""}
            titleSuffix={`(${ewData?.earlyWarningItem?.number})`}
            statusOptionId={ewData?.earlyWarningItem?.statusOptionId || ""}
            dataLoading={eWDataLoading || changeEWStatusLoading}
            productType={ProductType.EarlyWarnings}
            statusOptions={
              (ewData?.earlyWarningItem?.productInstance.statusCollection
                .statusOptions.items as ItemStatusOption[]) ?? []
            }
            isRecorded={!!ewData?.earlyWarningItem?.offline}
            onStatusChange={canChangeStatus ? handleChangeEWStataus : undefined}
            onBack={handleNavigateToEWRegister}
          />
          <Box mt={3}>
            {eWDataLoading || !ewData || !ewData.earlyWarningItem ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode={false}
                productItemType={ProductType.EarlyWarnings}
                schema={
                  ewData.earlyWarningItem.productInstance
                    .productSchema as ProductSchema
                }
                authAudit={
                  (ewData.earlyWarningItem
                    .authorizationWorkflowAudit as AuthorizationWorkflowAudit) ??
                  undefined
                }
                metadata={{
                  owner: ewData.earlyWarningItem.owner as User,
                  creator: ewData.earlyWarningItem.creator as User,
                  dateModified: ewData.earlyWarningItem.dateModified,
                  dateCreated: ewData.earlyWarningItem.dateCreated,
                  productItemId: ewData.earlyWarningItem.id!,
                  dateSent: ewData.earlyWarningItem.dateSent,
                  offline: ewData.earlyWarningItem.offline,
                }}
                schemaValues={ewData.earlyWarningItem.data}
                contractCurrency={
                  ewData.earlyWarningItem.productInstance.contract
                    .valueCurrency ?? ""
                }
                contractTimezone={
                  ewData.earlyWarningItem.productInstance.contract.timeZone ??
                  ""
                }
                productInstanceId={ewData.earlyWarningItem.productInstanceId}
                onOwnerChange={reloadChangelog}
                mainColumnExtraWidgets={
                  <>
                    <Grid item xs={12} key="attachments" position="relative">
                      <SectionContainer>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          timezone={
                            ewData?.earlyWarningItem?.productInstance.contract
                              .timeZone
                          }
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid item xs={12} key={"comments"} position="relative">
                      <SectionContainer>
                        <Comments
                          productType={ProductType.EarlyWarnings}
                          productItemId={ewItemId!}
                          productInstanceId={productInstanceId!}
                          onCommentAdded={reloadChangelog}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
                secondaryColumnExtraWidgets={
                  <>
                    <Box width="100%" key="changelog" position="relative">
                      <CollapsibleSectionContainer
                        collapsible
                        title="Changelog"
                        maxHeight="600px"
                        overflow="auto"
                      >
                        <Changelog
                          productItemId={ewData!.earlyWarningItem.id}
                          productType={ProductType.EarlyWarnings}
                          contractTimezone={
                            ewData?.earlyWarningItem.productInstance.contract
                              .timeZone ?? ""
                          }
                          productInstanceId={productInstanceId!}
                          ref={(apiRef) => {
                            setChangelogApiRef(apiRef!);
                          }}
                        />
                      </CollapsibleSectionContainer>
                    </Box>
                    {ewData.earlyWarningItem.notificationRecipients.length >
                      0 && (
                      <Box
                        width="100%"
                        key="notified-users"
                        position="relative"
                      >
                        <CollapsibleSectionContainer
                          collapsible
                          initialCollapseState={true}
                          title={
                            <NotifiedUsersWidgetHeader
                              recipientsCount={
                                ewData.earlyWarningItem.notificationRecipients
                                  .length
                              }
                            />
                          }
                        >
                          <NotifiedUsers
                            recipients={
                              ewData.earlyWarningItem.notificationRecipients
                            }
                          />
                        </CollapsibleSectionContainer>
                      </Box>
                    )}
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
