import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import {
  AuthorizationWorkflow,
  AuthorizationWorkflowStatus,
} from "generated/graphql";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useColumns } from "./ProjectAuthorizationWorkflows.constants";
import { exportToExcel } from "../../../../../../helpers/exportToExcel";
import { NewAppPaths } from "helpers/paths/paths";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { ShieldCheckered } from "phosphor-react";
import { StyledDataGrid } from "components/StyledDataGrid";
import { getUserName } from "helpers/miscelaneous";
import { excelDateTimeISOFormat } from "constants/constants";

export type ProjectAuthorizationWorkflowsProps = {
  workflows: AuthorizationWorkflow[];
  projectName?: string;
  loading?: boolean;
  onWorkflowStatusChange: (
    row: AuthorizationWorkflow,
    newStatus: AuthorizationWorkflowStatus
  ) => void;
};

export const ProjectAuthorizationWorkflows: React.FC<
  ProjectAuthorizationWorkflowsProps
> = ({ workflows, projectName, loading, onWorkflowStatusChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<AuthorizationWorkflowStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [showWorkflows, setShowWorkflows] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "dateCreated", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [AuthorizationWorkflowStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("common.labels.name"),
        key: "name",
      },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.createdBy"),
        key: "createdBy",
      },
      {
        header: t("common.labels.dateModified"),
        key: "dateModified",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.modifiedBy"),
        key: "modifiedBy",
      },
    ];

    const rows = workflows
      .filter((workflow) => (selectionModel || []).indexOf(workflow.id) >= 0)
      .map((workflow) => ({
        ...workflow,
        dateCreated: workflow.dateCreated ? new Date(workflow.dateCreated) : "",
        createdBy: getUserName(workflow.creator),
        dateModified: workflow.dateModified
          ? new Date(workflow.dateModified)
          : "",
        modifiedBy: getUserName(workflow.modifiedBy),
      }));

    exportToExcel(
      `${projectName}-${t(
        "AdminConsole.AuthorizationWorkflows.authorizationWorkflows"
      )}`,
      columns,
      rows
    );
  };

  const handleAddNewWorkflow = useCallback(() => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.Projects.children.ProjectDetails.children.NewAuthorizationWorkflow.pathConstructor(
        projectId!
      )
    );
  }, [navigate, projectId]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<AuthorizationWorkflow>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.Projects.children.AuthorizationWorkflowDetails.pathConstructor(
            projectId!,
            rowData.row.id
          )
        );
      }
    },
    [navigate, projectId]
  );

  const columns = useColumns(statusOptions, onWorkflowStatusChange);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.AuthorizationWorkflows.authorizationWorkflows")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t(
            "AdminConsole.AuthorizationWorkflows.authorizationWorkflow"
          ).toLowerCase(),
        })}
        actionBtnDataTestId="new-auth-workflow-btn"
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        onActionButtonClick={handleAddNewWorkflow}
        icon={<ShieldCheckered color={theme.palette.primary.main} />}
        onToggleCollapse={() => setShowWorkflows((state) => !state)}
        collapsed={!showWorkflows}
      />
      <Collapse in={showWorkflows}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={workflows}
            columns={columns}
            getRowId={(rowData: AuthorizationWorkflow) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            onRowClick={handleRowClick}
            getCellClassName={(
              params: GridCellParams<any, AuthorizationWorkflow, any>
            ) => {
              return params.row.status === AuthorizationWorkflowStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
          />
        </Box>
      </Collapse>
    </Box>
  );
};
