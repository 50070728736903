import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import { ContractBinding, ContractBindingStatus } from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { getUserName, getUserNameOrEmail } from "helpers/miscelaneous";
import { NewAppPaths } from "helpers/paths/paths";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { UsersThree } from "phosphor-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useColumns } from "./ContractBindings.constants";
import { excelDateTimeISOFormat } from "constants/constants";

export type ContractBindingsProps = {
  bindings: ContractBinding[];
  loading?: boolean;
  projectFriendlyName?: string;
  contractFriendlyName?: string;
  onBindingStatusChange: (
    row: ContractBinding,
    newStatus: ContractBindingStatus
  ) => void;
};

export const ContractBindings: React.FC<ContractBindingsProps> = ({
  bindings,
  loading,
  projectFriendlyName,
  contractFriendlyName,
  onBindingStatusChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { projectId, contractId } = useParams();
  const navigate = useNavigate();
  const contractBindingStatusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ContractBindingStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [showContractBindings, setShowContractBindings] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "companyId", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ContractBindingStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("common.labels.company"),
        key: "companyName",
      },
      {
        header: t("common.labels.bindingType"),
        key: "contractBindingTypeName",
      },
      { header: t("common.labels.status"), key: "status" },
      {
        header: t("AdminConsole.Bindings.labels.representative"),
        key: "representativeName",
      },
      {
        header: t("AdminConsole.Bindings.labels.billedTo"),
        key: "isCompanyBilled",
      },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        numFmt: excelDateTimeISOFormat,
      },
      {
        header: t("common.labels.createdBy"),
        key: "creator",
      },
    ];

    const rows = bindings
      .filter((binding) => (selectionModel || []).indexOf(binding.id) >= 0)
      .map((binding) => ({
        ...binding,
        companyName: binding.company.tradingName,
        contractBindingTypeName: binding.contractBindingType.description,
        representativeName: getUserNameOrEmail(binding.representative),
        dateCreated: binding.dateCreated ? new Date(binding.dateCreated) : "",
        creator: getUserName(binding.creator),
      }));

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}-${t(
        "AdminConsole.Bindings.labels.bindings"
      )}`,
      columns,
      rows
    );
  };

  const handleNewBinding = useCallback(() => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.Projects.children.ContractDetails.children.NewBinding.pathConstructor(
        projectId!,
        contractId!
      )
    );
  }, [navigate, projectId, contractId]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<ContractBinding>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.Projects.children.ContractDetails.children.BindingDetails.pathConstructor(
            projectId!,
            contractId!,
            rowData.row.id
          )
        );
      }
    },
    [navigate, projectId, contractId]
  );

  const columns = useColumns(
    theme,
    contractBindingStatusOptions,
    onBindingStatusChange
  );

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Bindings.labels.bindings")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t("common.labels.binding").toLowerCase(),
        })}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        withShadow={false}
        onActionButtonClick={handleNewBinding}
        icon={
          <UsersThree
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowContractBindings((state) => !state)}
        collapsed={!showContractBindings}
      />
      <Collapse in={showContractBindings}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={bindings}
            columns={columns}
            getRowId={(rowData: ContractBinding) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            onRowClick={handleRowClick}
            getCellClassName={(
              params: GridCellParams<any, ContractBinding, any>
            ) => {
              return params.row.status === ContractBindingStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
          />
        </Box>
      </Collapse>
    </Box>
  );
};
